





























































import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { errorNotification } from '@/lib/calendesk-js-library/tools/notification'
import { mapActions, mapGetters } from 'vuex'
import { SidebarDetailsType } from '@/builder/sidebar/sidebarDetails/SidebarDetailsType'
import Section from '@/calendesk/models/DTO/Response/Section'
import PreviewSection from '@/calendesk/models/PreviewSection'
import sectionComponents from '@/builder/sections/SectionComponents'
import SectionCategory from '@/calendesk/models/SectionCategory'
import SectionComponent from '@/calendesk/models/SectionComponent'
import SectionImage from '@/calendesk/models/DTO/Response/SectionImage'
import CPreviewSection from '@/components/CPreviewSection.vue'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import SectionConfigurationEmployee from '@/calendesk/models/SectionConfigurationEmployee'
import SectionConfigurationService from '@/calendesk/models/SectionConfigurationService'
import Service from '@/calendesk/models/DTO/Response/Service'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import Page from '@/calendesk/models/DTO/Response/Page'
import { SectionCategoryTypeId } from '@/calendesk/models/BuilderTypes'

export default mixins(DraftElement).extend({
  name: 'SelectSection',
  components: { CPreviewSection },
  data () {
    return {
      isLoading: false,
      sectionCategoryTypes: SectionCategoryTypeId
    }
  },
  computed: {
    ...mapGetters({
      sidebarDetails: 'sidebar/getSidebarDetails',
      employees: 'employee/getEmployees',
      services: 'service/getServices',
      draftPages: 'builder/getDraftPages'
    }),
    categoryType (): SectionCategoryTypeId | null {
      if (this.sidebarDetails.data.sections && this.sidebarDetails.data.sections.length > 0) {
        return this.sidebarDetails.data.category
      }

      return null
    },
    previewSections (): Array<PreviewSection> {
      const sections = Array<PreviewSection>()
      this.sidebarDetails.data.sections.forEach((sectionConfiguration: SectionConfiguration) => {
        sectionConfiguration = this.fillSectionConfigurationWithSampleData(sectionConfiguration)

        if (this.isLoginHidden &&
          this.sectionsRequiredLogin.includes(sectionConfiguration.configuration.component_id)) {
          return
        }

        const section = new Section(
          sectionConfiguration.type,
          sectionConfiguration.configuration,
          SectionImage.createFromDummyImages(sectionConfiguration.images))

        const previewSection = this.createPreviewSection(section)

        if (previewSection) {
          previewSection.sectionConfiguration = sectionConfiguration
          sections.push(previewSection)
        }
      })

      return sections
    },
    employeesSample () {
      const employees = Array<SectionConfigurationEmployee>()

      if (this.employees) {
        this.employees.forEach((employee: Employee) => {
          employees.push({ id: employee.id })
        })
      }

      return employees.slice(0, 3)
    },
    servicesSample () {
      const services = Array<SectionConfigurationService>()

      if (this.services) {
        this.services.forEach((service: Service) => {
          services.push({ id: service.id })
        })
      }

      return services
    },
    pageIdsSample (): Array<string> {
      const pageIds: Array<string> = []

      if (this.draftPages) {
        this.draftPages.forEach((page: Page) => {
          pageIds.push(page.uuid)
        })
      }

      return pageIds.slice(0, 5)
    }
  },
  created () {
    const requests = []
    if (!this.employees) {
      requests.push(this.fetchEmployees)
    }

    if (requests.length > 0) {
      this.isLoading = true
      Promise.all(requests).finally(() => {
        this.isLoading = false
      })
    }
  },
  methods: {
    ...mapActions({
      closeSidebarDetails: 'sidebar/closeSidebarDetails',
      closeSidebar: 'sidebar/closeSidebar',
      changeEmptyToNewSection: 'builder/changeEmptyToNewSection',
      replaceDraftSection: 'builder/replaceDraftSection',
      fetchEmployees: 'employee/fetch',
      fetchCategoryAndServices: 'category/fetchCategoryAndServices'
    }),
    reloadEmployees () {
      this.isLoading = true
      this.fetchEmployees().finally(() => {
        this.isLoading = false
      })
    },
    reloadServices () {
      this.isLoading = true
      this.fetchCategoryAndServices().finally(() => {
        this.isLoading = false
      })
    },
    fillSectionConfigurationWithSampleData (sectionConfiguration: SectionConfiguration): SectionConfiguration {
      if (sectionConfiguration.configuration.wb_employee_list__employees__) {
        sectionConfiguration.configuration.wb_employee_list__employees__ = this.employeesSample
      }

      if (sectionConfiguration.configuration.wb_service_list__services__) {
        sectionConfiguration.configuration.wb_service_list__services__ = this.servicesSample
      }

      if (sectionConfiguration.configuration.wb_page_list__page_ids__) {
        sectionConfiguration.configuration.wb_page_list__page_ids__ = this.pageIdsSample
      }

      if (sectionConfiguration.configuration.wb_menu_column_1__page_ids__) {
        sectionConfiguration.configuration.wb_menu_column_1__page_ids__ = this.pageIdsSample.slice(0, 2)
      }

      if (sectionConfiguration.configuration.wb_menu_column_2__page_ids__) {
        sectionConfiguration.configuration.wb_menu_column_2__page_ids__ = this.pageIdsSample.slice(3, 5)
      }

      return sectionConfiguration
    },
    getWrapHeight (previewSection: PreviewSection): string {
      return String(Number(previewSection.sectionConfiguration?.configuration.present_height) * 0.4) + 'px'
    },
    getContentHeight (previewSection: PreviewSection): string {
      return String(Number(previewSection.sectionConfiguration?.configuration.present_height)) + 'px'
    },
    createPreviewSection (section: Section): PreviewSection | null {
      const sectionType = sectionComponents.find((_: SectionCategory) => _.type === section.type)

      if (sectionType) {
        const type = sectionType.components.find((_: SectionComponent) => _.componentId === section.configuration.component_id)
        if (type) {
          return new PreviewSection(section, type.component)
        }
      }

      return null
    },
    chooseSection (sectionConfiguration: SectionConfiguration): void {
      this.isLoading = true

      sectionConfiguration = this.fillSectionConfigurationWithSampleData(sectionConfiguration)
      if (this.sidebarDetails.type === SidebarDetailsType.REPLACE_NAVBAR || this.sidebarDetails.type === SidebarDetailsType.REPLACE_FOOTER) {
        this.replaceDraftSection(sectionConfiguration)?.then(() => {
          this.closeSidebarDetails()
          this.closeSidebar()
        }).catch((error) => {
          errorNotification('operation_failed', error)
        }).finally(() => { this.isLoading = false })
      } else {
        this.changeEmptyToNewSection(sectionConfiguration)?.then(() => {
          this.closeSidebarDetails()
          this.closeSidebar()
        }).catch((error) => {
          errorNotification('operation_failed', error)
        }).finally(() => { this.isLoading = false })
      }
    }
  }
})
